import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, Link }  from 'gatsby'
import ds from '../theme'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div/Div.styles'
import H4 from '../components/H4'
import { css } from '@emotion/core'

import H3 from '../components/H3'
import Paragraph from '../components/Paragraph'
import OutlineButton from '../components/OutlinedButton'
import DcNordeste from '../images/data-center-nordeste.svg'
import DcSp from '../images/data-center-sp.svg'
import DcOrlando from '../images/data-center-usa.svg'

import { Container, mq } from '../components/styles/Layout.styles'
import { 
  styleStructure,
  header
} from '../components/styles/Structure.styles'
import { item } from '../components/styles/InternalPage.styles'

const DCNE = ({
  title,
  text,
  ctaText,
  icon: Icon,
  src,
}) => {
  return (
    <div
      style={{
        padding: 30,
        backgroundColor: '#FFFFFF',
        borderRadius: 16
      }}
      css={
        [css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        ),
        item
      ]
      }
    >
      <Div
        flexDirection='column'
        alignItems='left'
      >
        {Icon && <Icon />}
        {src && <img
          src={src}
          style={{
            width: 160,
            height: 160,
            marginBottom: 24
          }}
        />}
        <H4
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            width: 214,
            letterSpacing: 0.25,
            marginBottom: 8
          }}
        >
          {title}
        </H4>
        <Paragraph style={{ marginBottom: 24, height: 96 }}>
          {text}
        </Paragraph>
        <OutlineButton
          type='btnOutlinedSmall'
          Link='/data-center-nordeste'
        >
          {ctaText}
        </OutlineButton>
      </Div>
    </div>
  )
}

const DCSP = ({
  title,
  text,
  ctaText,
  icon: Icon,
  src,
  id
}) => {
  return (
    <div
      style={{
        padding: 30,
        backgroundColor: '#FFFFFF',
        borderRadius: 16
      }}
      css={
        [css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        ),
        item
      ]
      }
    >
      <Div
        flexDirection='column'
        alignItems='left'
      >
        {Icon && <Icon />}
        {src && <img
          src={src}
          style={{
            width: 160,
            height: 160,
            marginBottom: 24
          }}
        />}
        <H4
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            width: 214,
            letterSpacing: 0.25,
            marginBottom: 8
          }}
        >
          {title}
        </H4>
        <Paragraph style={{ marginBottom: 24, height: 96 }}>
          {text}
        </Paragraph>
        <OutlineButton
          type='btnOutlinedMedium_'
          Link='/data-center-sao-paulo'
        >
          {ctaText}
        </OutlineButton>
      </Div>
    </div>
  )
}

const DCUSA = ({
  title,
  text,
  ctaText,
  icon: Icon,
  src,
}) => {
  return (
    <div
      style={{
        padding: 30,
        backgroundColor: '#FFFFFF',
        borderRadius: 16
      }}
      css={
        [css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        ),
        item
      ]
      }
    >
      <Div
        flexDirection='column'
        alignItems='left'
      >
        {Icon && <Icon />}
        {src && <img
          src={src}
          style={{
            width: 160,
            height: 160,
            marginBottom: 24
          }}
        />}
        <H4
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            width: 214,
            letterSpacing: 0.25,
            marginBottom: 8
          }}
        >
          {title}
        </H4>
        <Paragraph style={{ marginBottom: 24, height: 96 }}>
          {text}
        </Paragraph>
        <OutlineButton
          type='btnOutlinedSmall'
          Link='/data-center-orlando'
        >
          {ctaText}
        </OutlineButton>
      </Div>
    </div>
  )
}

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <Fragment>
      <section css={styleStructure}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H3>Nossa Estrutura</H3>
              <Paragraph>A HostDime oferece excelência em todos os níveis de serviço através do uso inovador de tecnologia aplicada a soluções personalizadas para todas as necessidades dos clientes.</Paragraph>
              <Paragraph>Nossos Data Centers são referência e oferecem soluções multi redundantes em conectividade, climatização, segurança física e disponibilidade de energia.</Paragraph>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row', 'row']}
            alignItems={'center'}
            pt={34}
            pb={[50, 70]}
          >
            <Column
              pb={[30, 30, 30, 0]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <Link
                  to='/data-center-nordeste'
                >
                  <DCNE
                    src={DcNordeste}
                    title='Data Center Nordeste'
                    text='Atendimento multiplataforma ágil, eficiente e personalizado. Disponível sempre que você precisar.'
                    ctaText='conheça'
                  />
                </Link>
              </Div>
            </Column>
            <Column
              pb={[30, 30, 30, 0]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <Link
                  to='/data-center-sao-paulo'
                >
                  <DCSP
                    src={DcSp}
                    title='Data Center São Paulo'
                    text='A HostDime está presente nas localidades mais estratégicas para atender você.'
                    ctaText='veja os detalhes'
                  />
                </Link>
              </Div>
            </Column>
            <Column
              pb={[30, 30, 30, 0]}
            >
              <Div
                maxWidth={['100%', 328]}
              >
                <Link 
                  to='/data-center-orlando'
                >
                  <DCUSA
                    src={DcOrlando}
                    title='Data Center Orlando'
                    text='Localizada em Orlando, Flórida, nossa instalação foi projetada tendo em mente a estabilidade, confiabilidade e o nível mais elevado de padrões.'
                    ctaText='saiba mais'
                  />
                </Link>
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title='Excelência em estrutura | HostDime Brasil'
        description='Nossa estrutura é referência e oferece as melhores soluções em conectividade, climatização, segurança física e disponibilidade.'
      />
      <PageTemplate
        title='Nossa Estrutura'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
